import IconBasic from 'assets/publishers/offerwall/basic.png';
import IconCustomizing from 'assets/publishers/offerwall/customizing.png';
import IconNative from 'assets/publishers/offerwall/native.png';
import IconSwipe from 'assets/publishers/offerwall/swipe.png';
import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const CampaignType = () => {
  const { t } = useTranslation();

  const cards = useMemo(
    () => [
      {
        icon: IconBasic,
        title: t('publishers.offerwall.campaignType.basic.title'),
        description: t('publishers.offerwall.campaignType.basic.description'),
      },
      {
        icon: IconNative,
        title: t('publishers.offerwall.campaignType.native.title'),
        description: t('publishers.offerwall.campaignType.native.description'),
      },
      {
        icon: IconSwipe,
        title: t('publishers.offerwall.campaignType.swipe.title'),
        description: t('publishers.offerwall.campaignType.swipe.description'),
      },
      {
        icon: IconCustomizing,
        title: t('publishers.offerwall.campaignType.customizedUI.title'),
        description: t('publishers.offerwall.campaignType.customizedUI.description'),
      },
    ],
    [t],
  );

  return (
    <section
      className={cn(
        'flex flex-col bg-[#FBFCFD] justify-center items-center',
        'lg:py-[100px] lg:px-0',
        'py-[50px] px-[20px]',
      )}
    >
      <div className="flex flex-col">
        <h6
          className={cn(
            'text-center text-[#2685F4] text-[16px] leading-normal font-semibold',
            'lg:text-[14px] leading-[21px] lg:mb-[4px]',
            'text-[16px] leading-[24px] mb-[2px]',
          )}
        >
          Campaign Type
        </h6>
        <h3
          className={cn(
            'text-[#212529] text-center font-bold leading-[134%] tracking-[-0.3px] mb-[10px]',
            'lg:text-[36px]',
            'text-[24px]',
          )}
        >
          {t('publishers.offerwall.campaignType.title')}
        </h3>
        <p
          className={cn(
            'text-center text-[#808C99] font-normal tracking-[-0.3px] leading-[160%] mb-[30px]',
            'lg:text-[18px]',
            'text-[16px]',
          )}
        >
          {t('publishers.offerwall.campaignType.description')}
        </p>
      </div>
      <div
        className={cn(
          'flex justify-start w-full',
          'lg:max-w-[1200px] lg:flex-wrap lg:mt-[30px] lg:flex-row lg:gap-[40px]',
          'flex-col gap-[30px]',
        )}
      >
        {cards.map(({ icon, title, description }) => (
          <div key={title} className={cn('flex flex-col items-center', 'lg:w-[270px]', 'w-full')}>
            <div className={cn('lg:mb-[10px]', 'mb-[6px]')}>
              <img
                className={cn('lg:w-[104px] lg:h-[200px]', 'w-[62px] h-[120px]')}
                src={icon}
                alt="icon.png"
              />
            </div>
            <h5
              className={cn(
                'text-[#212529] font-semibold leading-[150%] tracking-[-0.3px] mb-[4px]',
                'lg:text-[20px]',
                'text-[18px]',
              )}
            >
              {title}
            </h5>
            <p
              className={cn(
                'text-center text-[#808C99] tracking-[-0.3px] font-normal',
                'lg:text-[16px] lg:leading-[160%]',
                'text-[14px] leading-[150%]',
              )}
            >
              {description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CampaignType;
