import { Head } from 'components/common';
import {
  AboutOfferwall,
  CampaignType,
  Intro,
  KeyFeature,
  MaximizeRevenue,
  UpliftLoyalty,
} from 'components/publishers/offerwall';
import MANUALS from 'constants/manuals';
import Layout from 'layout/index';
import { useTranslation } from 'react-i18next';

const OfferwallPage = () => {
  const { t } = useTranslation();

  return (
    <Layout starterConfig={{ mediaKit: MANUALS.offerwall, contactHash: '#publishers' }}>
      <Head title="Offerwall" description={t('publishers.offerwall.intro.description')} />
      <Intro mediaKit={MANUALS.offerwall} />
      <AboutOfferwall />
      <MaximizeRevenue />
      <UpliftLoyalty />
      <CampaignType />
      <KeyFeature />
    </Layout>
  );
};

export default OfferwallPage;
